@import 'utils/src/global.scss';

.NotifiSettingTable {
    &-Body {
        & > .Widget {
            padding: 24px;
        }
    }

    &-Container {
        overflow-x: auto;
    }

    &-Footer {
        position: sticky;
        bottom: 0;
        right: 0;
        left: 0;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        padding: 24px;
        border: 1px solid $BorderColor;
        background-color: #fff;
        z-index: 10;
        margin-top: 24px;
        margin-bottom: -24px;
        border-radius: 8px;
        button {
            margin-left: 24px;
        }
    }
}
