.AdditionalMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 16px;
    right: 5%;
    z-index: 20;
    pointer-events: all;

    &-Wrapper {
        position: fixed;
        top: 0;
        height: 100%;
        transform: translate(-50%);
        z-index: 20;
        pointer-events: none;
        @media (max-width: 750px) {
            right: 8px;
            bottom: 0;
            left: unset;
            height: unset;
            transform: unset;
            width: 100%;
        }
    }
    &-Item {
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }

    :nth-last-child(n + 2) {
        margin-bottom: 10px;
    }
}
